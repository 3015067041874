export class RequestConsultaPrecio{
    codPrestacion:string;
    codConvenio:string;
    fecha:string;
    hora:string;
    tipoAtencion:string;
    establecimiento:string;
    sistemaOrigen:string;
    idCita:string;
    constructor(
        codPrestacion:string,
        codConvenio:string,
        fecha:string,
        hora:string,
        tipoAtencion:string,
        establecimiento:string,
        sistemaOrigen:string,
        idCita:string
    ){
        this.codPrestacion = codPrestacion;
        this.codConvenio = codConvenio;
        this.fecha = fecha;
        this.hora = hora;
        this.tipoAtencion = tipoAtencion;
        this.establecimiento = establecimiento;
        this.sistemaOrigen = sistemaOrigen;
        this.idCita = idCita;
    }
}