import { RequestGenPrebonoLisPrestacion } from './requestGenPrebonoLisPrestacion';
import { throws } from "assert";

export class RequestGenPrebonoLisPrestador{

    RutConvenio:string;
    CorrConvenio:string;
    RutTratante:string;
	RutSolic:string;
	NomSolic:string;
	CodEspecia:string;
	RutBenef:string;
	EmailPaciente:string;
	IdAgenda:string;
	FecAgenda:string;
	HorAge:string;
	LisPrestacion:RequestGenPrebonoLisPrestacion[]=[];
    constructor(
        RutConvenio:string,
        CorrConvenio:string,
        RutTratante:string,
        RutSolic:string,
        NomSolic:string,
        CodEspecia:string,
        RutBenef:string,
        EmailPaciente:string,
        IdAgenda:string,
        FecAgenda:string,
        HorAge:string,
        LisPrestacion:RequestGenPrebonoLisPrestacion[]=[]
    ){
        this.RutConvenio = RutConvenio;
        this.CorrConvenio = CorrConvenio;
        this.RutTratante = RutTratante;
        this.RutSolic = RutSolic;
        this.NomSolic = NomSolic;
        this.CodEspecia = CodEspecia;
        this.RutBenef = RutBenef;
        this.EmailPaciente = EmailPaciente;
        this.IdAgenda = IdAgenda;
        this.FecAgenda = FecAgenda;
        this.HorAge = HorAge;
        this.LisPrestacion = LisPrestacion;
    }
}