import { RequestGenPrebonoLisPrestador } from './requestGenPrebonoLisPrestador';
export class RequestGeneraPrebono{

    CodUsuario:string;
    CodClave:string;
    OrigenLlamado:string;
    CanalGen:string;
    TipEvento:string;
    CodFinanciador:string;
    CodLugar:number;
    RutCajero:string;
    LisPrestador:RequestGenPrebonoLisPrestador[]=[];
    constructor(
        CodUsuario:string,
        CodClave:string,
        OrigenLlamado:string,
        CanalGen:string,
        TipEvento:string,
        CodFinanciador:string,
        CodLugar:number,
        RutCajero:string,
        LisPrestador:RequestGenPrebonoLisPrestador[]=[]
    ){
        this.CodUsuario = CodUsuario;
        this.CodClave = CodClave;
        this.OrigenLlamado = OrigenLlamado;
        this.CanalGen = CanalGen;
        this.TipEvento = TipEvento;
        this.CodFinanciador = CodFinanciador;
        this.CodLugar = CodLugar;
        this.RutCajero = RutCajero;
        this.LisPrestador = LisPrestador;
    }
}