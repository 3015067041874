export class S2ConfirmPayment {
    
    methodPayment:string;
    terminoCondiciones:string;
    constructor(
        methodPayment:string,
        terminoCondiciones:string
    ){
        this.methodPayment = methodPayment;
        this.terminoCondiciones = terminoCondiciones;
    }
}