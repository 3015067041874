export class ResponseBdpTransaccion{

    id:string;
    doctor:string;
    cita:string;
    hora:string;
    fecha:string;
    especialidad:string;
    duracion:string;
    correo:string;
    telefono:string;
    nombre:string;
    id_tipo_documento:string;
    rut:string;
    data:string;
    token:string;
    flowOrder:string;
    commerceOrder:string;
    requestDate:string;
    status:string;
    currency:string;
    amount:string;
    payer:string;
    paymentData_date:string;
	paymentData_media:string;
	paymentData_amount:string;
	paymentData_fee:string;
	paymentData_taxes:string;
	paymentData_balance:string;
	paymentData_transferDate:string;
	registro_ejecucion:string;
	response_status_code:string;
	id_estado_transaccion:string;
	id_financiador:string;
	created_at:string;
	updated_at:string;
    constructor(
        id:string,
        doctor:string,
        cita:string,
        hora:string,
        fecha:string,
        especialidad:string,
        duracion:string,
        correo:string,
        telefono:string,
        nombre:string,
        id_tipo_documento:string,
        rut:string,
        data:string,
        token:string,
        flowOrder:string,
        commerceOrder:string,
        requestDate:string,
        status:string,
        currency:string,
        amount:string,
        payer:string,
        paymentData_date:string,
        paymentData_media:string,
        paymentData_amount:string,
        paymentData_fee:string,
        paymentData_taxes:string,
        paymentData_balance:string,
        paymentData_transferDate:string,
        registro_ejecucion:string,
        response_status_code:string,
        id_estado_transaccion:string,
        id_financiador:string,
        created_at:string,
        updated_at:string,
    ){
        this.id = id;
        this.doctor = doctor;
        this.cita = cita;
        this.hora = hora;
        this.fecha = fecha;
        this.especialidad = especialidad;
        this.duracion = duracion;
        this.correo = correo;
        this.telefono = telefono;
        this.nombre = nombre;
        this.id_tipo_documento = id_tipo_documento;
        this.rut = rut;
        this.data = data;
        this.token = token;
        this.flowOrder = flowOrder;
        this.commerceOrder = commerceOrder;
        this.requestDate = requestDate;
        this.status = status;
        this.currency = currency;
        this.amount = amount;
        this.payer = payer;
        this.paymentData_date = paymentData_date;
        this.paymentData_media = paymentData_media;
        this.paymentData_amount = paymentData_amount;
        this.paymentData_fee = paymentData_fee;
        this.paymentData_taxes = paymentData_taxes;
        this.paymentData_balance = paymentData_balance;
        this.paymentData_transferDate = paymentData_transferDate;
        this.registro_ejecucion = registro_ejecucion;
        this.response_status_code = response_status_code;
        this.id_estado_transaccion = id_estado_transaccion;
        this.id_financiador = id_financiador;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
}