import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S1InfoPatientService } from '../service/s1-info-patient.service';
import { S2ConfirmPaymentService } from '../service/s2-confirm-payment.service';
import { S3DetailPaymentService } from '../service/s3-detail-payment.service';
import { GenPrebonoIMEDService } from '../service/gen-prebono-imed.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    S1InfoPatientService,
    S2ConfirmPaymentService,
    S3DetailPaymentService,
    GenPrebonoIMEDService
  ],
  declarations: []
})
export class ServiceModule { }