export class ResponsePaymentGetStatusPaymentData{

    date:string;
    media:string;
    conversionDate:string;
    conversionRate:string;
    amount:string;
    currency:string;
    fee:string;
    balance:string;
    transferDate:string;
    constructor(
        date:string,
        media:string,
        conversionDate:string,
        conversionRate:string,
        amount:string,
        currency:string,
        fee:string,
        balance:string,
        transferDate:string
    ){
        this.date = date;
        this.media = media;
        this.conversionDate = conversionDate;
        this.conversionRate = conversionRate;
        this.amount = amount;
        this.currency = currency;
        this.fee = fee;
        this.balance = balance;
        this.transferDate = transferDate;
    }
}