import { Pipe, PipeTransform } from '@angular/core';

const PADDING = "";

@Pipe({
  name: 'rut'
})
export class RutPipe implements PipeTransform {

  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    // TODO Puedes configurar los separadores que prefieras
    this.DECIMAL_SEPARATOR = ",";
    this.THOUSANDS_SEPARATOR = ".";
  }

  transform(value: number | string): string {
    let [ integer, fraction = "" ] = (value || "").toString()
      .split(this.DECIMAL_SEPARATOR); // Divide entre parte entera y decimal, por la "," en este caso

    let largoRut = integer.length;
    integer = integer.substring(0,largoRut-2).replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR)+integer.substring(largoRut-2,largoRut);

    return integer + fraction;
  }

  parse(value: string): string {
    let [ integer, fraction = "" ] = (value || "").split(this.DECIMAL_SEPARATOR);

    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");

    return integer + fraction;
  }
}