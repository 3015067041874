import { Desencriptado } from './../model/s1-info-patient/desencriptado';

import { Injectable } from '@angular/core';
import { RequestGeneraPrebono } from '../model/s2-confirm-payment/requestGeneraPrebono';
import { ResponseGenPrebono } from '../model/s2-confirm-payment/responseGenPrebono';
import { ResponseCodigoHisImed } from '../model/s2-confirm-payment/responseCodigoHisImed';

import { S2ConfirmPaymentService } from '../service/s2-confirm-payment.service';

import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ResponseConsultaCitasByGuid } from '../model/s1-info-patient/responseConsultaCitasByGuid';
import { RequestConsultaPrecio } from '../model/s2-confirm-payment/requestConsultaPrecio';
import { ResponseConsultaPrecio } from '../model/s2-confirm-payment/responseConsultaPrecio';

import { Router } from '@angular/router';
import { Location } from '@angular/common'


@Injectable({
  providedIn: 'root'
})
export class GenPrebonoIMEDService {

  requestConsultaPrecioModel: RequestConsultaPrecio = new RequestConsultaPrecio("","","","","","","","")
  responseConsultaPrecioModel: ResponseConsultaPrecio = new ResponseConsultaPrecio("","","","","","","","",0,"");
  responseGenPrebonoModel: ResponseGenPrebono = new ResponseGenPrebono("","","","","","",null);
  valorPrestacion:any=0;
  valorCopago:any=0;
  valorSeguro:any=0;
  valorFinal:any=0;
  valorPrecioHIS:any=0;
  valorPrecioIMED:any=0;
  fechaCita:any
  rutPaciente:any;

  desencriptadoModel: Desencriptado = new Desencriptado("","","","","","","","","","","","","","","","");

  constructor(
              private service: S2ConfirmPaymentService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private location: Location        
              ) { }

  // async genPrebonoSrv(responseConsultaCitasByGuidModel: ResponseConsultaCitasByGuid,
  //                     requestConsultaPrecioModel: RequestConsultaPrecio,
  //                     responseConsultaPrecioModel: ResponseConsultaPrecio,                      
  //                     requestGenPrebonoModel:RequestGeneraPrebono,
  //                     desencriptadoModel: Desencriptado,
  //                     financiadorHIS: string){ 
  //                     //id_codigo_lugar_imed:number, 
  //                     //rut_convenio_imed:string){

  //   console.log('requestGenPrebonoModel: ', requestGenPrebonoModel)
  //   //this.responseGenPrebonoModel = await this.genPrebonoEntpoint(requestGenPrebonoModel, id_codigo_lugar_imed, rut_convenio_imed);
  //   this.responseGenPrebonoModel = await this.genPrebonoEntpoint(requestGenPrebonoModel, responseConsultaPrecioModel.codigoLugarImed, responseConsultaPrecioModel.rutConvenio);
  //   console.log('responseGenPrebonoModel: ', this.responseGenPrebonoModel)
    
  //   if(this.responseGenPrebonoModel.resultado != "0" && this.responseGenPrebonoModel.resultado != "2" && this.responseGenPrebonoModel.resultado != "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Particular"){      
  //     this.spinner.hide()
  //     Swal.fire({
  //       text: this.responseGenPrebonoModel.glosa,
  //       confirmButtonText: 'Particular',
  //       denyButtonText: 'Cerrar',
  //       showDenyButton: true    
  //     }).then((result) => {
  //       if(result.isConfirmed){
  //         this.forceParticular(responseConsultaCitasByGuidModel, requestConsultaPrecioModel);
  //       }else if(result.isDenied){
  //         this.redirectResalud();
  //       }
  //     })
  //   }

  //   if(this.responseGenPrebonoModel.resultado == "2" && this.responseGenPrebonoModel.tipoMsj == "Force-Reintentar-Financiador"){      
  //     this.spinner.hide()
  //     Swal.fire({
  //       text: this.responseGenPrebonoModel.glosa,
  //       confirmButtonText: 'Nuevo Financiador',
  //       denyButtonText: 'Particular',
  //       cancelButtonText: 'Cerrar',
  //       showDenyButton: true,
  //       showCancelButton: true,          
  //     }).then((result) => {
  //       if(result.isConfirmed){
  //         this.forceReintentarFinanciador();
  //       }else if(result.isDenied){
  //         this.forceParticular(responseConsultaCitasByGuidModel, requestConsultaPrecioModel);
  //       }else if(result.isDismissed){
  //         this.redirectResalud();
  //       }
  //     })
  //   }

  //   if(this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Redireccionar-RedSalud"){      
  //     this.spinner.hide()
  //     Swal.fire({
  //       text: this.responseGenPrebonoModel.glosa,
  //       //confirmButtonText: 'Particular',
  //       denyButtonText: 'Cerrar',
  //       showDenyButton: true    
  //     }).then((result) => {
  //       //if(result.isConfirmed){
  //         //this.forceParticular(responseConsultaCitasByGuidModel, requestConsultaPrecioModel);
  //       //}else if(result.isDenied){
  //         this.redirectResalud();
  //       //}
  //     })
  //   }

  //   if(this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Particular"){      
  //     this.spinner.hide()
  //     Swal.fire({
  //       text: this.responseGenPrebonoModel.glosa,
  //       confirmButtonText: 'Particular',
  //       denyButtonText: 'Cerrar',
  //       showDenyButton: true    
  //     }).then((result) => {
  //       if(result.isConfirmed){
  //         this.forceParticular(responseConsultaCitasByGuidModel, requestConsultaPrecioModel);
  //       }else if(result.isDenied){
  //         this.redirectResalud();
  //       }
  //     })
  //   }

  //   if(this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Reintentar-Particular"){        
  //     this.spinner.hide()
  //     Swal.fire({
  //       text: this.responseGenPrebonoModel.glosa,
  //       confirmButtonText: 'Reintentar',
  //       denyButtonText: 'Particular',
  //       cancelButtonText: 'Cerrar',
  //       showDenyButton: true,
  //       showCancelButton: true,          
  //     }).then((result) => {
  //       if(result.isConfirmed){
  //         this.forceReintentar(responseConsultaCitasByGuidModel, financiadorHIS, desencriptadoModel);
  //       }else if(result.isDenied){
  //         this.forceParticular(responseConsultaCitasByGuidModel, requestConsultaPrecioModel);
  //       }else if(result.isDismissed){
  //         this.redirectResalud();
  //       }
  //     })
  //   }else{
  //     if(this.responseGenPrebonoModel.datos.CodError != "2"){
  //       this.valorPrestacion = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal; 
  //       this.valorCopago = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MontoBonif; 
  //       this.valorSeguro = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MonCiaSeg; 
  //       this.valorFinal = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoCopagoFinal;
  //       if(this.valorFinal == 0){
  //         this.spinner.hide()

  //         console.log('requestPagoCompletadoCopago0: ', responseConsultaCitasByGuidModel.Citas[0].IdCita)
  //         await this.pagoCompletadoCopago0(responseConsultaCitasByGuidModel.Citas[0].IdCita)
  //         console.log('responsePagoCompletadoCopago0: OK')

  //         Swal.fire({
  //           text: 'Su pago y/o bonificación fueron recibidos con éxito',
  //           confirmButtonText: 'Cerrar',
  //         }).then((result) => {
  //           if(result.isConfirmed){
  //             this.redirectResalud();
  //           }
  //         })
  //       }
  //       this.valorPrecioIMED = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal;
  //     }
       
  //   }

  //   if(this.valorPrecioIMED != 0){
  //     this.spinner.hide()
  //     if(this.valorPrecioIMED >= this.valorPrecioHIS){
  //       this.valorPrestacion = this.valorPrecioIMED
  //     }else{          
  //       Swal.fire({
  //       text: 'Lo sentimos, no ha sido posible procesar su pago.',
  //       confirmButtonText: 'Cerrar',
  //       }).then((result) => {
  //         if(result.isConfirmed){
  //           this.redirectResalud();
  //         }
  //       })
  //     }
  //   }

  //   return this.responseGenPrebonoModel
  // }; 

  // redirectResalud(){
  //   if(this.responseGenPrebonoModel.accion != ""){
  //     window.location.href = this.responseGenPrebonoModel.accion;
  //   }else{
  //     window.location.href = "https://www.redsalud.cl";
  //   }
    
  // }

  // async forceParticular(responseConsultaCitasByGuidModel:ResponseConsultaCitasByGuid, requestConsultaPrecioModel: RequestConsultaPrecio){
  //   this.spinner.show()
    
  //   requestConsultaPrecioModel.codPrestacion = responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0].CodPrestacion
  //   requestConsultaPrecioModel.codConvenio = "CMPART"
  //   requestConsultaPrecioModel.fecha = responseConsultaCitasByGuidModel.Citas[0].FechaCita
  //   requestConsultaPrecioModel.hora = responseConsultaCitasByGuidModel.Citas[0].HoraCita
  //   requestConsultaPrecioModel.idCita = responseConsultaCitasByGuidModel.Citas[0].IdCita    
  //   requestConsultaPrecioModel.establecimiento = responseConsultaCitasByGuidModel.Citas[0].CodCentro

  //   console.log("requestConsultaPrecioForceParticular: ", requestConsultaPrecioModel)
  //   this.responseConsultaPrecioModel = await this.consultaPrecio(requestConsultaPrecioModel,responseConsultaCitasByGuidModel.Citas[0].CodCentro)
  //   console.log("responseConsultaPrecioForceParticular: ", this.responseConsultaPrecioModel)

  //   this.rutPaciente = responseConsultaCitasByGuidModel.Citas[0].RutBeneficiario
  //   this.valorPrestacion = this.responseConsultaPrecioModel.precio
  //   this.valorCopago = "0";
  //   this.valorFinal = this.responseConsultaPrecioModel.precio
  //   this.fechaCita = responseConsultaCitasByGuidModel.Citas[0].FechaCita
  //   this.fechaCita = this.fechaCita.substring(6,10)+"-"+this.fechaCita.substring(3,5)+"-"+this.fechaCita.substring(0,2)
  //   let route = '/S2ConfirmPayment';
  //   this.router.navigate([route]);
  //   this.spinner.hide()
  // }

  // forceReintentar(responseConsultaCitasByGuidModel: ResponseConsultaCitasByGuid, financiadorHIS: string, desencriptadoModel: Desencriptado){
  //   console.log('reintentar')
  //   // let route = '/S2ConfirmPayment';
  //   // this.router.navigate([route], { state: { responseConsultaCitasByGuidModel: responseConsultaCitasByGuidModel,
  //   //   convenio: financiadorHIS,                                            
  //   //   desencriptadoModel: this.desencriptadoModel
  //   // }});
  //   //this.location.back()
  //   //this.ngOnInit()
  //   //window.location.reload()
  //   this.location.back()
  // }

  // forceReintentarFinanciador(){
  //   this.location.back()
  // }

  // // async genPrebonoEntpoint(RequestGeneraPrebono, codLugar, rutConvenio){
  // //   return new Promise<any>((resolve, reject) => {
  // //     let res = this.service.genPrebonoService(RequestGeneraPrebono, codLugar, rutConvenio)
  // //     .then((data) => {             
  // //       resolve(data)              
  // //     });
  // //   })
  // // } 

  // async pagoCompletadoCopago0(cita){
  //   return new Promise<any>((resolve, reject) => {
  //     let res = this.service.pagoCompletadoCopago0(cita)
  //     .then((data) => {             
  //       resolve(data)              
  //     });
  //   })
  // }

  // async consultaPrecio(requestConsultaPrecio, codCentro){
  //   return new Promise<any>((resolve, reject) => {
  //     let res = this.service.consultaPrecio(requestConsultaPrecio, codCentro)
  //       .then((data) => {             
  //         resolve(data)              
  //     });
  //   })
  // } 

}
