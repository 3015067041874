import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'pages',
    loadChildren: './pages/pages.module#PagesModule'
  }
];
console.log("router principal")
export const APP_ROUTES = RouterModule.forRoot( routes, { useHash: true } );
