export class Desencriptado{

    doctor:string;
    cita:string;    
    hora:string;
    fecha:string;
    especialidad:string;
    duracion:string;
    correo:string;
    telefono:string;
    nombre:string;
    tipo_documento:string;
    prevision:string;
    rut:string;
    resultado:string;
    glosa:string;
    tipoMsj:string;
    accion:string;
    constructor(        
        doctor:string,
        cita:string,  
        hora:string,
        fecha:string,
        especialidad:string,
        duracion:string,
        correo:string,
        telefono:string,
        nombre:string,
        tipo_documento:string,
        prevision:string,
        rut:string,
        resultado:string,
        glosa:string,
        tipoMsj:string,
        accion:string
    ){
        this.doctor = doctor;
        this.cita = cita;
        this.hora = hora;    
        this.fecha = fecha;
        this.especialidad = especialidad;
        this.duracion = duracion;
        this.correo = correo;
        this.telefono = telefono;
        this.nombre = nombre;
        this.tipo_documento = tipo_documento;
        this.prevision = prevision;
        this.rut = rut;    
        this.resultado = resultado;
        this.glosa = glosa;
        this.tipoMsj = tipoMsj;
        this.accion = accion;
    }

}