import { RequestPagoPrebonoFormasPago } from './../../../model/s2-confirm-payment/requestPagoPrebonoFormasPago';
import { ResponseCodigoHisImed } from './../../../model/s2-confirm-payment/responseCodigoHisImed';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { RequestPrebonoLisPreBonoPrestacionesInpLisFormasPagoBonoFormasPago } from '../../../model/s2-confirm-payment/requestPrebonoLisPreBonoPrestacionesInpLisFormasPagoBonoFormasPago';
import { RequestPrebonoLisPreBonoPrestacionesInp } from '../../../model/s2-confirm-payment/requestPrebonoLisPreBonoPrestacionesInp';
import { RequestGenPrebonoLisPrestacion } from '../../../model/s2-confirm-payment/requestGenPrebonoLisPrestacion';
import { RequestGenPrebonoLisPrestador } from '../../../model/s2-confirm-payment/requestGenPrebonoLisPrestador';
import { ResponseFinanciador } from '../../../model/s2-confirm-payment/responseFinanciador';
import { RequestFinanciador } from '../../../model/s2-confirm-payment/requestFinanciador';
import { ResponseGenPrebono } from '../../../model/s2-confirm-payment/responseGenPrebono';
import { RequestGeneraPrebono } from '../../../model/s2-confirm-payment/requestGeneraPrebono';
import { Desencriptado } from './../../../model/s1-info-patient/desencriptado';
import { RequestPaymentCreate } from '../../../model/s2-confirm-payment/requestPaymentCreate';
import { ResponsePaymentCreate } from '../../../model/s2-confirm-payment/responsePaymentCreate';
import { RequestConsultaPrecio } from '../../../model/s2-confirm-payment/requestConsultaPrecio';
import { ResponseConsultaPrecio } from '../../../model/s2-confirm-payment/responseConsultaPrecio';
import { ResponseConsultaCitasByGuid } from './../../../model/s1-info-patient/responseConsultaCitasByGuid';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { S1InfoPatient } from 'src/app/model/s1-info-patient/s1-info-patient';
import { S2ConfirmPayment } from 'src/app/model/s2-confirm-payment/s2-confirm-payment';

import { S2ConfirmPaymentService } from '../../../service/s2-confirm-payment.service';
import { GenPrebonoIMEDService } from '../../../service/gen-prebono-imed.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common'
import Swal from 'sweetalert2';

import { ThousandsPipe } from '../../../pipes/thousands.pipe';
import { RutPipe } from '../../../pipes/rut.pipe';

@Component({
  selector: 'app-s2-confirm-payment',
  templateUrl: './s2-confirm-payment.component.html'
})
export class S2ConfirmPaymentComponent implements OnInit {

  dataForm = new FormGroup({
    methodPayment: new FormControl(),
    terminoCondiciones: new FormControl()
  });

  s1InfoPatient: S1InfoPatient = new S1InfoPatient("", "", "", "", "", "");
  s2ConfirmPayment: S2ConfirmPayment = new S2ConfirmPayment("","");

  desencriptadoModel: Desencriptado = new Desencriptado("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "");
  responseConsultaCitasByGuidModel: ResponseConsultaCitasByGuid = new ResponseConsultaCitasByGuid("", "", "", "", []);

  requestConsultaPrecioModel: RequestConsultaPrecio = new RequestConsultaPrecio("", "", "", "", "", "", "", "")
  responseConsultaPrecioModel: ResponseConsultaPrecio = new ResponseConsultaPrecio("", "", "", "", "", "", "", "", 0, "");

  requestPaymentCreateModel: RequestPaymentCreate = new RequestPaymentCreate("", "", "", "", "", "", "", "", "", "", "", "", "", "");
  responsePaymentCreateModel: ResponsePaymentCreate = new ResponsePaymentCreate("", "", "", "", "", "", "");

  requestFinanciadorModel: RequestFinanciador = new RequestFinanciador("", "");
  responseFinanciadorModel: ResponseFinanciador = new ResponseFinanciador("", "", "", "", "", "");
  // responseCodigoHisImed: ResponseCodigoHisImed = new ResponseCodigoHisImed("","","","","","","",0,"","");

  requestGenPrebonoModel: RequestGeneraPrebono = new RequestGeneraPrebono("", "", "", "", "", "", 0, "", []);
  requestGenPrebonoLisPrestadorModel: RequestGenPrebonoLisPrestador = new RequestGenPrebonoLisPrestador("", "", "", "", "", "", "", "", "", "", "", [])
  requestGenPrebonoLisPrestacionModel: RequestGenPrebonoLisPrestacion = new RequestGenPrebonoLisPrestacion("", "", "", "")
  responseGenPrebonoModel: ResponseGenPrebono = new ResponseGenPrebono("", "", "", "", "", "", null);


  requestPrebonoLisPreBonoPrestacionesInpModel: RequestPrebonoLisPreBonoPrestacionesInp = new RequestPrebonoLisPreBonoPrestacionesInp(0, []);

  requestPrebonoLisPreBonoPrestacionesInpLisFormasPagoBonoFormasPago: RequestPrebonoLisPreBonoPrestacionesInpLisFormasPagoBonoFormasPago = new RequestPrebonoLisPreBonoPrestacionesInpLisFormasPagoBonoFormasPago(0, 0);
  requestPagoPrebonoFormasPago: RequestPagoPrebonoFormasPago = new RequestPagoPrebonoFormasPago(0, 0);

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private service: S2ConfirmPaymentService,
    private serviceGenPrebonoImed: GenPrebonoIMEDService,
    private spinner: NgxSpinnerService,
    private location: Location,
    public thousands: ThousandsPipe,
    public rut: RutPipe) {
    this.responseConsultaCitasByGuidModel = this.router.getCurrentNavigation().extras.state.responseConsultaCitasByGuidModel
    this.desencriptadoModel = this.router.getCurrentNavigation().extras.state.desencriptadoModel
    this.financiadorHIS = this.router.getCurrentNavigation().extras.state.convenio
  }

  financiadorHIS: any;
  fechaCita: any
  rutPaciente: any;
  valorPrestacion: any = 0;
  valorCopago: any = 0;
  valorSeguro: any = 0;
  valorFinal: any = 0;
  params: any;
  centroMedico: string;

  valorPrecioHIS: any = 0;
  valorPrecioIMED: any = 0;
  clicked = false;

  async ngOnInit() {
    this.spinner.show()
    console.log(this.responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0])
    if (this.responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0] == undefined) {
      this.spinner.hide()
      Swal.fire('Cita sin Prestaci�n')
    }
    this.requestConsultaPrecioModel.codPrestacion = this.responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0].CodPrestacion
    this.requestConsultaPrecioModel.codConvenio = this.financiadorHIS
    this.requestConsultaPrecioModel.fecha = this.responseConsultaCitasByGuidModel.Citas[0].FechaCita
    this.requestConsultaPrecioModel.hora = this.responseConsultaCitasByGuidModel.Citas[0].HoraCita
    this.requestConsultaPrecioModel.idCita = this.responseConsultaCitasByGuidModel.Citas[0].IdCita
    this.requestConsultaPrecioModel.establecimiento = this.responseConsultaCitasByGuidModel.Citas[0].CodCentro

    console.log("requestConsultaPrecio: ", this.requestConsultaPrecioModel)
    this.responseConsultaPrecioModel = await this.consultaPrecio(this.requestConsultaPrecioModel, this.responseConsultaCitasByGuidModel.Citas[0].CodCentro)
    console.log("responseConsultaPrecio: ", this.responseConsultaPrecioModel)

    this.rutPaciente = this.responseConsultaCitasByGuidModel.Citas[0].RutBeneficiario
    this.valorPrestacion = this.responseConsultaPrecioModel.precio
    this.valorCopago = "0";
    this.valorSeguro = "0"
    this.valorFinal = this.responseConsultaPrecioModel.precio
    this.fechaCita = this.responseConsultaCitasByGuidModel.Citas[0].FechaCita
    this.fechaCita = this.fechaCita.substring(6, 10) + "-" + this.fechaCita.substring(3, 5) + "-" + this.fechaCita.substring(0, 2)
    this.valorPrecioHIS = this.responseConsultaPrecioModel.precio

    this.params = `{"params":"${this.responseConsultaCitasByGuidModel.Citas[0].RutBeneficiario}|${this.desencriptadoModel.correo}|${this.desencriptadoModel.telefono}|${this.responseConsultaCitasByGuidModel.Citas[0].RutTratante}|${this.responseConsultaCitasByGuidModel.Citas[0].CodigoEspecialidad}|${this.responseConsultaCitasByGuidModel.Citas[0].IdCita}|${this.responseConsultaCitasByGuidModel.Citas[0].HoraCita}|${this.desencriptadoModel.duracion}|${this.fechaCita}|${this.responseConsultaCitasByGuidModel.Citas[0].NombreBeneficiario}"}`

    this.requestPaymentCreateModel.apiKey = "";
    this.requestPaymentCreateModel.commerceOrder = this.responseConsultaCitasByGuidModel.Citas[0].IdCita;
    this.requestPaymentCreateModel.amount = this.responseConsultaPrecioModel.precio;
    this.requestPaymentCreateModel.email = this.desencriptadoModel.correo;
    this.requestPaymentCreateModel.paymentMethod = "";
    this.requestPaymentCreateModel.urlReturn = "";
    this.requestPaymentCreateModel.optional = this.params.toString();
    this.requestPaymentCreateModel.merchantId = "";
    this.requestPaymentCreateModel.payment_currency = "";
    this.requestPaymentCreateModel.s = "";

    this.requestFinanciadorModel.financiadorHIS = this.financiadorHIS
    this.requestFinanciadorModel.codCentro = this.responseConsultaCitasByGuidModel.Citas[0].CodCentro

    // console.log('requestFinanciadorModel: ', this.requestFinanciadorModel)
    // this.responseCodigoHisImed = await this.dataFinanciador(this.requestFinanciadorModel.financiadorHIS, this.requestFinanciadorModel.codCentro);
    // console.log('responseFinanciadorModel: ', this.responseCodigoHisImed)

    //console.log("this.responseCodigoHisImed.asegurador_his: ", this.responseCodigoHisImed.asegurador_his)
    if (this.requestFinanciadorModel.financiadorHIS != "CMPART") {
      //this.requestGenPrebonoModel.CodFinanciador = this.responseCodigoHisImed.id_financiador;
      this.requestGenPrebonoModel.CodFinanciador = this.responseConsultaPrecioModel.codigoFinanciador;
      this.requestGenPrebonoModel.CodLugar = this.responseConsultaPrecioModel.codigoLugarImed;
      this.requestGenPrebonoLisPrestadorModel.RutTratante = "00" + this.responseConsultaCitasByGuidModel.Citas[0].RutTratante;
      if (this.responseConsultaCitasByGuidModel.Citas[0].RutSolicitante == "") {
        this.requestGenPrebonoLisPrestadorModel.RutSolic = "0000000000-0";
      } else {
        this.requestGenPrebonoLisPrestadorModel.RutSolic = this.responseConsultaCitasByGuidModel.Citas[0].RutSolicitante;
      }

      this.requestGenPrebonoLisPrestadorModel.NomSolic = this.responseConsultaCitasByGuidModel.Citas[0].NombreSolicitante;
      this.requestGenPrebonoLisPrestadorModel.CodEspecia = this.responseConsultaPrecioModel.especImed;
      this.requestGenPrebonoLisPrestadorModel.RutBenef = this.responseConsultaCitasByGuidModel.Citas[0].RutBeneficiario;
      this.requestGenPrebonoLisPrestadorModel.IdAgenda = this.responseConsultaCitasByGuidModel.Citas[0].IdCita;
      this.requestGenPrebonoLisPrestadorModel.FecAgenda = this.responseConsultaCitasByGuidModel.Citas[0].FechaCita;
      this.requestGenPrebonoLisPrestadorModel.HorAge = this.responseConsultaCitasByGuidModel.Citas[0].HoraCita;
      this.requestGenPrebonoLisPrestacionModel.CodPrestacion = this.responseConsultaPrecioModel.codItemImed;
      this.requestGenPrebonoLisPrestacionModel.CodItem = this.responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0].CodItem;
      this.requestGenPrebonoLisPrestacionModel.Cantidad = this.responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0].Cantidad;
      //this.requestGenPrebonoLisPrestacionModel.MtoTotal = this.responseConsultaPrecioModel.precio;
      this.requestGenPrebonoLisPrestacionModel.MtoTotal = "0";

      this.requestGenPrebonoModel.LisPrestador[0] = this.requestGenPrebonoLisPrestadorModel;
      this.requestGenPrebonoModel.LisPrestador[0].LisPrestacion[0] = this.requestGenPrebonoLisPrestacionModel;

      this.centroMedico = this.responseConsultaCitasByGuidModel.Citas[0].CodCentro;

      console.log('LisPrestador: ', this.requestGenPrebonoModel.CodLugar)

      // this.responseGenPrebonoModel = await this.serviceGenPrebonoImed.genPrebonoSrv(this.responseConsultaCitasByGuidModel,
      //                                                                               this.requestConsultaPrecioModel,
      //                                                                               this.responseConsultaPrecioModel,
      //                                                                               this.requestGenPrebonoModel,
      //                                                                               this.desencriptadoModel,
      //                                                                               this.financiadorHIS)
      //this.responseCodigoHisImed.id_codigo_lugar_imed, 
      //this.responseCodigoHisImed.rut_convenio_imed)

      console.log('requestGenPrebonoModel: ', this.requestGenPrebonoModel)
      this.responseGenPrebonoModel = await this.genPrebono(this.requestGenPrebonoModel, this.responseConsultaPrecioModel.codigoLugarImed, this.responseConsultaPrecioModel.rutConvenio);
      console.log('responseGenPrebonoModel: ', this.responseGenPrebonoModel)

      // if (this.responseGenPrebonoModel.resultado == "0") {
      //   this.valorPrestacion = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal;
      //   this.valorCopago = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MontoBonif;
      //   this.valorSeguro = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MonCiaSeg;
      //   this.valorFinal = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoCopagoFinal;
      // //   if (this.valorFinal >= 0 && this.valorFinal <= 350 ) {
      // //     this.spinner.hide()

      // //     // console.log('requestPagoCompletadoCopago0: ', this.responseConsultaCitasByGuidModel.Citas[0].IdCita)
      // //     // await this.pagoCompletadoCopago0(this.responseConsultaCitasByGuidModel.Citas[0].IdCita)
      // //     // console.log('responsePagoCompletadoCopago0: OK')

      // //     Swal.fire({
      // //       //text: 'Su pago y/o bonificación fueron recibidos con éxito',
      // //       text: 'Estimado paciente, su copago es inferior a $350 por lo cual necesitamos que realice su pago en el centro.',
      // //       confirmButtonText: 'Cerrar',
      // //     }).then((result) => {
      // //       if (result.isConfirmed) {
      // //         this.redirectResalud();
      // //       }
      // //     })
      // //   }
      // //   this.valorPrecioIMED = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal;
      // }

      if (this.responseGenPrebonoModel.resultado != "0" && this.responseGenPrebonoModel.resultado != "2" && this.responseGenPrebonoModel.resultado != "4" && this.responseGenPrebonoModel.resultado != "5" && this.responseGenPrebonoModel.tipoMsj == "Force-Particular") {
        this.spinner.hide()
        Swal.fire({
          text: this.responseGenPrebonoModel.glosa,
          confirmButtonText: 'Particular',
          denyButtonText: 'Cerrar',
          showDenyButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.forceParticular();
          } else if (result.isDenied) {
            this.redirectResalud();
          }
        })
      }

      if (this.responseGenPrebonoModel.resultado == "2" && this.responseGenPrebonoModel.tipoMsj == "Force-Reintentar-Financiador") {
        this.spinner.hide()
        Swal.fire({
          text: this.responseGenPrebonoModel.glosa,
          confirmButtonText: 'Nuevo Financiador',
          denyButtonText: 'Particular',
          cancelButtonText: 'Cerrar',
          showDenyButton: true,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.forceReintentarFinanciador();
          } else if (result.isDenied) {
            this.forceParticular();
          } else if (result.isDismissed) {
            this.redirectResalud();
          }
        })
      }

      if (this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Redireccionar-RedSalud") {
        this.spinner.hide()
        Swal.fire({
          text: this.responseGenPrebonoModel.glosa,          
          denyButtonText: 'Cerrar',
          showDenyButton: true
        }).then((result) => {
          this.redirectResalud();
        })
      }

      // if (this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Particular") {
      //   this.spinner.hide()
      //   Swal.fire({
      //     text: this.responseGenPrebonoModel.glosa,
      //     confirmButtonText: 'Particular',
      //     denyButtonText: 'Cerrar',
      //     showDenyButton: true
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.forceParticular();
      //     } else if (result.isDenied) {
      //       this.redirectResalud();
      //     }
      //   })
      // }

      // if (this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Reintentar-Particular") {
      //   this.spinner.hide()
      //   Swal.fire({
      //     text: this.responseGenPrebonoModel.glosa,
      //     confirmButtonText: 'Reintentar',
      //     denyButtonText: 'Particular',
      //     cancelButtonText: 'Cerrar',
      //     showDenyButton: true,
      //     showCancelButton: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.forceReintentar();
      //     } else if (result.isDenied) {
      //       this.forceParticular();
      //     } else if (result.isDismissed) {
      //       this.redirectResalud();
      //     }
      //   })
      // } 

      if(this.responseGenPrebonoModel.resultado == "5"){
        // this.valorPrestacion = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal; 
        // this.valorCopago = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MontoBonif; 
        // this.valorSeguro = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MonCiaSeg; 
        // this.valorFinal = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoCopagoFinal;
        //if(this.valorFinal == 0){
          this.spinner.hide()

          // console.log('requestPagoCompletadoCopago0: ', this.responseConsultaCitasByGuidModel.Citas[0].IdCita)
          // await this.pagoCompletadoCopago0(this.responseConsultaCitasByGuidModel.Citas[0].IdCita)
          // console.log('responsePagoCompletadoCopago0: OK')

          Swal.fire({
            text: "Su cita ha sido valorizada con éxito, podrá continuar el pago a través del financiador " + this.responseGenPrebonoModel.glosa,
            confirmButtonText: 'Cerrar',
          }).then((result) => {
            // if(result.isConfirmed){
            //   this.redirectResalud();
            // }
          })
        //}
        // this.valorPrecioIMED = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal;
      }

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      if(this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Particular"){      
        this.spinner.hide()
        Swal.fire({
          text: this.responseGenPrebonoModel.glosa,
          confirmButtonText: 'Particular',
          denyButtonText: 'Cerrar',
          showDenyButton: true    
        }).then((result) => {
          if(result.isConfirmed){
            this.forceParticular();
          }else if(result.isDenied){
            this.redirectResalud();
          }
        })
      }

      if(this.responseGenPrebonoModel.resultado == "4" && this.responseGenPrebonoModel.tipoMsj == "Force-Reintentar-Particular"){        
        this.spinner.hide()
        Swal.fire({
          text: this.responseGenPrebonoModel.glosa,
          confirmButtonText: 'Reintentar',
          denyButtonText: 'Particular',
          cancelButtonText: 'Cerrar',
          showDenyButton: true,
          showCancelButton: true,          
        }).then((result) => {
          if(result.isConfirmed){
            this.forceReintentar();
          }else if(result.isDenied){
            this.forceParticular();
          }else if(result.isDismissed){
            this.redirectResalud();
          }
        })
      }else{
        if(this.responseGenPrebonoModel.datos.CodError != "2"){
          this.valorPrestacion = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal; 
          this.valorCopago = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MontoBonif; 
          this.valorSeguro = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MonCiaSeg; 
          this.valorFinal = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoCopagoFinal;
          if(this.valorFinal == 0){
            // this.spinner.hide()

            // console.log('requestPagoCompletadoCopago0: ', this.responseConsultaCitasByGuidModel.Citas[0].IdCita)
            // await this.pagoCompletadoCopago0(this.responseConsultaCitasByGuidModel.Citas[0].IdCita)
            // console.log('responsePagoCompletadoCopago0: OK')

            // Swal.fire({
            //   text: 'Su pago y/o bonificación fueron recibidos con éxito',
            //   confirmButtonText: 'Cerrar',
            // }).then((result) => {
            //   if(result.isConfirmed){
            //     this.redirectResalud();
            //   }
            // })
          }
          this.valorPrecioIMED = this.responseGenPrebonoModel.datos.LisPrebono[0].LisPrestVal[0].MtoTotal;
        }

      }

      if (this.valorPrecioIMED != 0) {
        this.spinner.hide()
        if (this.valorPrecioIMED >= this.valorPrecioHIS) {
          this.valorPrestacion = this.valorPrecioIMED
        } else {
          Swal.fire({
            text: 'Lo sentimos, no ha sido posible procesar su pago.',
            confirmButtonText: 'Cerrar',
          }).then((result) => {
            if (result.isConfirmed) {
              this.redirectResalud();
            }
          })
        }
      }
    }


    this.spinner.hide()
    this.dataForm = this.formBuilder.group({
      methodPayment: ['', [Validators.required]],
      terminoCondiciones: [false, Validators.requiredTrue]
      //terminoCondiciones: ['', [Validators.required]]
    }
    );
  }

  async consultaPrecio(requestConsultaPrecio, codCentro) {
    return new Promise<any>((resolve, reject) => {
      let res = this.service.consultaPrecio(requestConsultaPrecio, codCentro)
        .then((data) => {
          resolve(data)
        });
    })
  }

  s1Page() {
    this.s2ConfirmPayment.methodPayment = ''
    this.s2ConfirmPayment.terminoCondiciones = ''
    this.location.back()
  }

  async s3Page(event: Event) {

    console.log('methodPayment: ', this.s2ConfirmPayment.methodPayment);
    this.spinner.show()
    if (this.s2ConfirmPayment.methodPayment == "webpay") {
      this.requestPaymentCreateModel.paymentMethod = "13";
    } else {
      this.requestPaymentCreateModel.paymentMethod = "5";
    }

    this.requestPaymentCreateModel.amount = this.valorFinal;
    console.log('requestPaymentCreate: ', this.requestPaymentCreateModel)
    this.responsePaymentCreateModel = await this.paymentCreate(this.requestPaymentCreateModel);
    console.log('responsePaymentCreate: ', this.responsePaymentCreateModel)

    if (this.responsePaymentCreateModel.code == "1901" && this.responsePaymentCreateModel.TipoMsj == "Redireccionar-RedSalud") {
      this.spinner.hide()
      Swal.fire({
        text: this.responseGenPrebonoModel.glosa,
        denyButtonText: 'Cerrar',
        showDenyButton: true
      }).then((result) => {
        this.redirectResalud();
      })
    }

    if (this.responsePaymentCreateModel.url != null) {
      this.spinner.hide()
      let urlFlow = this.responsePaymentCreateModel.url + '?token=' + this.responsePaymentCreateModel.token;
      window.location.href = urlFlow
    } else {
      this.redirectResalud();
    }

  }

  // async dataFinanciador(financiadorHIS, codCentro){
  //   return new Promise<any>((resolve, reject) => {
  //     let res = this.service.dataFinanciador(financiadorHIS, codCentro)
  //     .then((data) => {             
  //       resolve(data)              
  //     });
  //   })
  // } 

  async genPrebono(RequestGeneraPrebono, codLugar, rutConvenio) {
    return new Promise<any>((resolve, reject) => {
      let res = this.service.genPrebono(RequestGeneraPrebono, codLugar, rutConvenio)
        .then((data) => {
          resolve(data)
        });
    })
  }

  async paymentCreate(request: RequestPaymentCreate) {
    return new Promise<any>((resolve, reject) => {
      let res = this.service.paymentCreate(request)
        .then((data) => {
          resolve(data)
        });
    })
  }

  redirectResalud() {
    // if (this.responseGenPrebonoModel.accion != "") {
    //   window.location.href = this.responseGenPrebonoModel.accion;
    // } else {
      window.location.href = "https://www.redsalud.cl";
    // }

  }

  async forceParticular() {
    this.spinner.show()

    this.requestConsultaPrecioModel.codPrestacion = this.responseConsultaCitasByGuidModel.Citas[0].Prestaciones[0].CodPrestacion
    this.requestConsultaPrecioModel.codConvenio = "CMPART"
    this.requestConsultaPrecioModel.fecha = this.responseConsultaCitasByGuidModel.Citas[0].FechaCita
    this.requestConsultaPrecioModel.hora = this.responseConsultaCitasByGuidModel.Citas[0].HoraCita
    this.requestConsultaPrecioModel.idCita = this.responseConsultaCitasByGuidModel.Citas[0].IdCita
    this.requestConsultaPrecioModel.establecimiento = this.responseConsultaCitasByGuidModel.Citas[0].CodCentro

    console.log("requestConsultaPrecio: ", this.requestConsultaPrecioModel)
    this.responseConsultaPrecioModel = await this.consultaPrecio(this.requestConsultaPrecioModel, this.responseConsultaCitasByGuidModel.Citas[0].CodCentro)
    console.log("responseConsultaPrecio: ", this.responseConsultaPrecioModel)

    this.rutPaciente = this.responseConsultaCitasByGuidModel.Citas[0].RutBeneficiario
    this.valorPrestacion = this.responseConsultaPrecioModel.precio
    this.valorCopago = "0";
    this.valorFinal = this.responseConsultaPrecioModel.precio
    this.fechaCita = this.responseConsultaCitasByGuidModel.Citas[0].FechaCita
    this.fechaCita = this.fechaCita.substring(6, 10) + "-" + this.fechaCita.substring(3, 5) + "-" + this.fechaCita.substring(0, 2)
    let route = '/S2ConfirmPayment';
    this.router.navigate([route]);
    this.spinner.hide()
  }

  forceReintentar() {
    this.ngOnInit()
  }

  forceReintentarFinanciador() {
    this.location.back()
  }

  async pagoCompletadoCopago0(cita) {
    return new Promise<any>((resolve, reject) => {
      let res = this.service.pagoCompletadoCopago0(cita)
        .then((data) => {
          resolve(data)
        });
    })
  }

}
