// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   firebaseConfig: {
//     apiKey: "AIzaSyD9Fm8qcOBsujYIgPVPvESeyoj-WotjXHU",
//     authDomain: "dev-pago-web-redsalud.firebaseapp.com",
//     databaseURL: "https://dev-pago-web-redsalud.firebaseio.com",
//     projectId: "dev-pago-web-redsalud",
//     storageBucket: "dev-pago-web-redsalud.appspot.com",
//     messagingSenderId: "425277371797"
//   },
//   url_services : "http://localhost:8080",//"https://api-dev-pago-web-ambulatorio-redsalud.redsalud.cl",
//   token_api_kocha: "Basic YmRwYW1idWxhdG9yaW86YmRwNG1idWw0dDByaTA="  
// };

// export const environment = {
//   production: true,
//   firebaseConfig: {
//     apiKey: "AIzaSyD9Fm8qcOBsujYIgPVPvESeyoj-WotjXHU",
//     authDomain: "qa-pago-web-redsalud.firebaseapp.com",
//     databaseURL: "https://qa-pago-web-redsalud.firebaseio.com",
//     projectId: "qa-pago-web-redsalud",
//     storageBucket: "qa-pago-web-redsalud.appspot.com",
//     messagingSenderId: "1032303512454"
//   },  
//   url_services : "http://localhost:8080",//"https://api-qa-pago-web-ambulatorio-redsalud.redsalud.cl",
//   token_api_kocha: "Basic YmRwYW1idWxhdG9yaW86YmRwNG1idWw0dDByaTA="
// };

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyD9Fm8qcOBsujYIgPVPvESeyoj-WotjXHU",
    authDomain: "prod-pago-web-redsalud.firebaseapp.com",
    databaseURL: "https://prod-pago-web-redsalud.firebaseio.com",
    projectId: "prod-pago-web-redsalud",
    storageBucket: "prod-pago-web-redsalud.appspot.com",
    messagingSenderId: "936250592813"
  },
  url_services : "https://api-pago-web-ambulatorio-redsalud.redsalud.cl",
  token_api_kocha: "Basic YmRwYW1idWxhdG9yaW86YmRwNG1idWw0dDByaTA="
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
