import { ResponseGenPrebonoDatos } from './responseGenPrebonoDatos';
export class ResponseGenPrebono{

    estado:string;
    mensaje:string;
    resultado:string;
    glosa:string;
    tipoMsj:string;
    accion:string;
    datos:ResponseGenPrebonoDatos;
    constructor(
        estado:string,
        mensaje:string,
        resultado:string,
        glosa:string,
        tipoMsj:string,
        accion:string,
        datos:ResponseGenPrebonoDatos
    ){
        this.estado = estado;
        this.mensaje = mensaje;
        this.resultado = resultado;
        this.glosa = glosa;
        this.tipoMsj = tipoMsj;
        this.accion = accion;
        this.datos = datos;
    }
}