import { Injectable } from '@angular/core';

import { map ,catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S1InfoPatientService {

  constructor(private http: HttpClient) { }

  async desencriptar(sked){

    let myHeaders = new HttpHeaders();

    myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
    let requestOptions = {
      headers: myHeaders,
      reportProgress: true,
    };

    return this.http.post(environment.url_services+"/api/desencriptar?encryptedData="+sked,{responseType:'json'},requestOptions)
                    .pipe(
                          map( (resp: any) => {
                            //console.log('response', resp);
                          return resp;

                          }),
                          catchError(err => {

                            console.log("Error is handled", err);

                            return err;
                          }))
                    .toPromise();
  };   

  async citasPacientesByGuid(idSked){

    let myHeaders = new HttpHeaders();

    myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
    let requestOptions = {
      headers: myHeaders,
      reportProgress: true,
    };

    const body = {
      'IdSked':idSked
    }

    return this.http.post(environment.url_services+"/api/his/citasPacientesByGuid",body,requestOptions)
                    .pipe(
                          map( (resp: JSON) => {
                            //console.log('response', resp);
                          return resp;

                          }),
                          catchError(err => {

                            console.log("Error is handled", err);

                            return err;
                          }))
                    .toPromise();
  };                         
  
}