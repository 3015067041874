export class ResponseFinanciador{

    id:string;
    nombre:string;
    financiadorSKED:string;
    financiadorHIS:string;
    financiadorIMED:string;
    estado:string;
    constructor(
        id:string,
        nombre:string,
        financiadorSKED:string,
        financiadorHIS:string,
        financiadorIMED:string,
        estado:string
    ){
        this.id = id;
        this.nombre = nombre;
        this.financiadorSKED = financiadorSKED;
        this.financiadorHIS = financiadorHIS;
        this.financiadorIMED = financiadorIMED;
        this.estado = estado;
    }
}