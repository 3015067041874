export class ResponseDataFinalizarPago {

    rutPaciente:string;
    tokenFlow:string;
    financiadorSked:string;
    numActaVenta:string;
    codLugar:string;
    numPreBono:string;
    mtoPago:string;
    mtoCopagoFinal:string;
    constructor(
        rutPaciente:string,
        tokenFlow:string,
        financiadorSked:string,
        numActaVenta:string,
        codLugar:string,
        numPreBono:string,
        mtoPago:string,
        mtoCopagoFinal:string
    ){
        this.rutPaciente = rutPaciente;
        this.tokenFlow = tokenFlow;
        this.financiadorSked = financiadorSked;
        this.numActaVenta = numActaVenta;
        this.codLugar = codLugar;
        this.numPreBono = numPreBono;
        this.mtoPago = mtoPago;
        this.mtoCopagoFinal = mtoCopagoFinal;
    }
}