export class RequestFinanciador{

    financiadorHIS:string;
    codCentro:string;
    constructor(
        financiadoHIS:string,
        codCentro:string
    ){
        this.financiadorHIS = financiadoHIS;
        this.codCentro = codCentro;
    }
}