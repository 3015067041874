import { ResponsePaymentGetStatusPaymentData } from "./responsePaymentGetStatusPaymentData";
import { ResponsePaymentGetStatusPendingInfo } from "./responsePaymentGetStatusPendingInfo";

export class ResponsePaymentGetStatus{

    flowOrder:string;
    commerceOrder:string;
    requestDate:string;
    status:string;
    subject:string;
    currency:string;
    amount:string;
    payer:string;
    optional:string;
    pendingInfo:ResponsePaymentGetStatusPendingInfo[];
    paymentData:ResponsePaymentGetStatusPaymentData[];
    merchantId:string;
    constructor(
        flowOrder:string,
        commerceOrder:string,
        requestDate:string,
        status:string,
        subject:string,
        currency:string,
        amount:string,
        payer:string,
        optional:string,
        pendingInfo:ResponsePaymentGetStatusPendingInfo[],
        paymentData:ResponsePaymentGetStatusPaymentData[],
        merchantId:string
    ){
        this.flowOrder = flowOrder;
        this.commerceOrder = commerceOrder;
        this.requestDate = requestDate;
        this.status = status;
        this.subject = subject;
        this.currency = currency;
        this.amount = amount;
        this.payer = payer;
        this.optional = optional;
        this.pendingInfo = pendingInfo;
        this.paymentData = paymentData;
        this.merchantId = merchantId;
    }

}