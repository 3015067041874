export class ResponseCodigoHisImed{

    id:string;
    id_financiador:string;
    nombre_financiador:string;
    financiador_sked:string;
    id_centro_his:string;
    glosa_centro_his:string;
    asegurador_his:string;
    id_codigo_lugar_imed:number;
    glosa_codigo_lugar_imed:string;
    rut_convenio_imed:string;
    constructor(
        id:string,
        id_financiador:string,
        nombre_financiador:string,
        financiador_sked:string,
        id_centro_his:string,
        glosa_centro_his:string,
        asegurador_his:string,
        id_codigo_lugar_imed:number,
        glosa_codigo_lugar_imed:string,
        rut_convenio_imed:string,
    ){
        this.id = id;
        this.id_financiador = id_financiador;
        this.nombre_financiador = nombre_financiador;
        this.financiador_sked = financiador_sked;
        this.id_centro_his = id_centro_his;
        this.glosa_centro_his = glosa_centro_his;
        this.asegurador_his = asegurador_his;
        this.id_codigo_lugar_imed = id_codigo_lugar_imed;
        this.glosa_codigo_lugar_imed = glosa_codigo_lugar_imed;
        this.rut_convenio_imed = rut_convenio_imed;
    }
}