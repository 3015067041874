import { ResponseBdpTransaccion } from './../../../model/s3-detail-payment/responseBdpTransaccion';
import { ResponseDataFinalizarPago } from './../../../model/s3-detail-payment/responseDataFinalizarPago';
import { RequestGenPrebonoLisPrestador } from './../../../model/s2-confirm-payment/requestGenPrebonoLisPrestador';
import { ResponseCodigoHisImed } from './../../../model/s2-confirm-payment/responseCodigoHisImed';
import { ResponsePagoPrebono } from './../../../model/s2-confirm-payment/responsePagoPrebono';
import { RequestPagoPrebono } from './../../../model/s2-confirm-payment/requestPagoPrebono';
import { ResponseGenPrebono } from './../../../model/s2-confirm-payment/responseGenPrebono';
import { RequestUpdateAppointment } from '../../../model/s3-detail-payment/requestUpdateAppointment';
import { ResponseUpdateAppointment } from '../../../model/s3-detail-payment/responseUpdateAppointment';
import { ResponsePaymentGetStatus } from '../../../model/s3-detail-payment/responsePaymentGetStatus';
import { Component, OnInit } from '@angular/core';
import { ResponseConsultaCitasByGuid } from './../../../model/s1-info-patient/responseConsultaCitasByGuid';
import { S3DetailPaymentService } from '../../../service/s3-detail-payment.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Location } from '@angular/common'

import { RutPipe } from '../../../pipes/rut.pipe';
import { ResponsePaymentGetStatusPaymentData } from 'src/app/model/s3-detail-payment/responsePaymentGetStatusPaymentData';
import { Desencriptado } from 'src/app/model/s1-info-patient/desencriptado';
import { RequestFinanciador } from 'src/app/model/s2-confirm-payment/requestFinanciador';
import { ResponseFinanciador } from 'src/app/model/s2-confirm-payment/responseFinanciador';

@Component({
  selector: 'app-s3-detail-payment',
  templateUrl: './s3-detail-payment.component.html'
})
export class S3DetailPaymentComponent implements OnInit {

  cita:any;
  rutPaciente:any;
  valorFinal:any;
  token:any;
  message:any;

  topMessagePagado:string="NOK"
  topMessageAnulado:string="NOK"
  imageDigital:string="NOK";
  footerPagado:string="NOK";
  footerAnulado:string="NOK";


  desencriptadoModel: Desencriptado = new Desencriptado("","","","","","","","","","","","","","","","");
  responseConsultaCitasByGuidModel: ResponseConsultaCitasByGuid = new ResponseConsultaCitasByGuid("","","","",[]);
  requestFinanciadorModel: RequestFinanciador = new RequestFinanciador("","");
  responseFinanciadorModel: ResponseFinanciador = new ResponseFinanciador("","","","","","");
  responseCodigoHisImed: ResponseCodigoHisImed = new ResponseCodigoHisImed("","","","","","","",0,"","");
  //convenio:any;

  responseDataFinalizarPagoModel: ResponseDataFinalizarPago = new ResponseDataFinalizarPago("","","","","","","","");

  responsePaymentGetStatusModel: ResponsePaymentGetStatus = new ResponsePaymentGetStatus("","","","","","","","","",[],[],"");
  responsePaymentGetStatusPaymentData : ResponsePaymentGetStatusPaymentData = new ResponsePaymentGetStatusPaymentData("","","","","","","","","");

  responseBdpTransaccion: ResponseBdpTransaccion = new ResponseBdpTransaccion("","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","")

  constructor(private router: Router, 
              private service: S3DetailPaymentService,
              private spinner: NgxSpinnerService,
              public rut: RutPipe,
              private location: Location) 
            {}

  async ngOnInit() {
    this.spinner.show()

    this.cita = window.location.href.split('cita=')
    this.cita = this.cita[this.cita.length-1]          
    console.log('cita: ',this.cita)

    console.log('requestDataFinalizarPago: ', this.token)
    this.responseDataFinalizarPagoModel = await this.getDataFinalizarPago(this.cita)
    console.log('responseDataFinalizarPago: ', this.responseDataFinalizarPagoModel)

    this.rutPaciente = this.responseDataFinalizarPagoModel.rutPaciente;
    this.token = this.responseDataFinalizarPagoModel.tokenFlow;
    this.valorFinal = this.responseDataFinalizarPagoModel.mtoPago;

    console.log('requestPaymentGetStatus: ', this.token)
    if( this.token != 'Invalid Signature'){
      this.responsePaymentGetStatusModel = await this.getStatus(this.token)
      console.log('responsePaymentGetStatus: ', this.responsePaymentGetStatusModel)
      console.log('responsePaymentGetStatusPaymentData: ', this.responsePaymentGetStatusPaymentData.media)

      if(this.responsePaymentGetStatusModel.status == "1" || this.responsePaymentGetStatusModel.status == "3"){
        //this.message = "Pago ha sido Anulado"
        this.topMessageAnulado = "OK"
        //this.message = "Pago Anulado"
        this.footerAnulado = "OK";
        Swal.fire({
          text: "El pago de su cita no ha sido concretado, ¿desea reintentar?",
          confirmButtonText: 'Reintentar',          
          cancelButtonText: 'Cerrar',      
          showCancelButton: true,          
        }).then((result) => {
          if(result.isConfirmed){
            this.forceReintentar();
          }else if(result.isDismissed){
            this.redirectResalud();
          }
        })
      }

      if(this.responsePaymentGetStatusModel.status == "2"){
        this.topMessagePagado = "OK"
        this.message = "Pago Exitoso"
        this.imageDigital = "OK"
        this.footerPagado = "OK"        
      }

      // if(this.responsePaymentGetStatusModel.status == "3"){
      //   this.message = "Pago Rechazado"
      // }

      if(this.responsePaymentGetStatusModel.status == "4"){
        this.topMessageAnulado = "OK"
        //this.message = "Pago Anulado"
        this.footerAnulado = "OK";
      }
      
    }

    this.spinner.hide()
  }

  async getDataFinalizarPago(cita){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.getDataFinalizarPago(cita)
      .then((data) => {             
        resolve(data)              
      });
    })
  }

  async getStatus(token){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.paymentGetStatus(token)
      .then((data) => {             
        resolve(data)              
      });
    })
  } 

  async updateAppointment(request){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.updateAppointment(request)
      .then((data) => {             
        resolve(data)              
      });
    })
  } 

  async pagoPrebono(RequestPagoPrebono, IdCita){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.pagoPrebono(RequestPagoPrebono, IdCita)
      .then((data) => {             
        resolve(data)              
      });
    })
  } 

  async forceReintentar(){    
    this.spinner.show()
    console.log('requestConsultaCitasByGuid: ', this.cita)
    this.responseConsultaCitasByGuidModel = await this.citasPacientesByGuid(this.cita)
    console.log('responseConsultaCitasByGuid: ', this.responseConsultaCitasByGuidModel)

    console.log('requestBdpTransaccion: ', this.cita)
    this.responseBdpTransaccion = await this.dataByCita(this.cita)
    console.log('responseBdpTransaccion: ', this.responseBdpTransaccion)

    this.desencriptadoModel.correo = this.responseBdpTransaccion.correo;
    console.log('this.desencriptadoModel.correo: ', this.desencriptadoModel.correo)
    this.desencriptadoModel.telefono = this.responseBdpTransaccion.telefono;
    console.log('this.desencriptadoModel.telefono: ', this.desencriptadoModel.telefono)
    this.desencriptadoModel.duracion = this.responseBdpTransaccion.duracion;
    console.log('this.desencriptadoModel.duracion: ', this.desencriptadoModel.duracion)

    this.requestFinanciadorModel.financiadorHIS = this.responseDataFinalizarPagoModel.financiadorSked
    this.requestFinanciadorModel.codCentro = this.responseConsultaCitasByGuidModel.Citas[0].CodCentro
    console.log('requestFinanciadorModel: ', this.requestFinanciadorModel)
    this.responseCodigoHisImed = await this.dataFinanciadorHis(this.requestFinanciadorModel.financiadorHIS, this.requestFinanciadorModel.codCentro);
    console.log('responseFinanciadorModel: ', this.responseCodigoHisImed)
    console.log('this.responseCodigoHisImed.asegurador_his: ', this.responseCodigoHisImed.asegurador_his)

    let route = '/S2ConfirmPayment';
    this.router.navigate([route], { state: { responseConsultaCitasByGuidModel: this.responseConsultaCitasByGuidModel,                                             
      desencriptadoModel: this.desencriptadoModel,
      convenio: this.responseCodigoHisImed.asegurador_his
    }});
  }

  async citasPacientesByGuid(idSked){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.citasPacientesByGuid(idSked)
      .then((data) => {             
        resolve(data)             
      });
    })
  } 

  async desencriptar(sked){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.desencriptar(sked)
      .then((data) => {
        resolve(data)              
      });
    })
  }  

  async dataFinanciadorHis(financiadorHIS, codCentro){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.dataFinanciadorHis(financiadorHIS, codCentro)
      .then((data) => {             
        resolve(data)              
      });
    })
  } 

  async dataByCita(idCita){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.dataByCita(idCita)
      .then((data) => {             
        resolve(data)              
      });
    })
  } 

  redirectResalud(){
    window.location.href = "https://www.redsalud.cl";
  }

}
