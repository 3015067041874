export class RequestPaymentCreate{
    apiKey:string;
    commerceOrder:string;
    subject:string;
    currency:string;
    amount:string;
    email:string;
    paymentMethod:string;
    urlConfirmation:string;
    urlReturn:string;
    optional:string;
    timeout:string;
    merchantId:string;
    payment_currency:string;
    s:string;
    constructor(
        apiKey:string,
        commerceOrder:string,
        subject:string,
        currency:string,
        amount:string,
        email:string,
        paymentMethod:string,
        urlConfirmation:string,
        urlReturn:string,
        optional:string,
        timeout:string,
        merchantId:string,
        payment_currency:string,
        s:string
    ){
        this.apiKey = apiKey;
        this.commerceOrder = commerceOrder;
        this.subject = subject;
        this.currency = currency;
        this.amount = amount;
        this.email = email;
        this.paymentMethod = paymentMethod;
        this.urlConfirmation = urlConfirmation;
        this.urlReturn = urlReturn;
        this.optional = optional;
        this.timeout = timeout;
        this.merchantId = merchantId;
        this.payment_currency = payment_currency;
        this.s = s;
    }
}