<!doctype html>
<html lang="es">
<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Pago Web Ambulatorio | RedSalud</title>
</head>
<body>  
  <div class="wrapper">
    <header>
      <img src="../assets/img/logo.png" />
    </header>
    <div class="row col m-0 p-0">
      <div class="col-12 container">
        <h1>Pago Web Ambulatorio RedSalud</h1>
        <div class="stepwizard col-md-offset-3">
          <div class="stepwizard col-md-offset-3">
            <div class="stepwizard-row setup-panel">
              <div class="stepwizard-step">
                <div id="header_step1" href="#step-1" type="button" class="btn btn-primary" style="cursor:auto" disabled="disabled"><small>Paso 1</small>Información del Paciente</div>
              </div>
              <div class="stepwizard-step">
                <div id="header_step2" href="#step-2" type="button" class="btn btn-default" style="cursor:auto" disabled="disabled"><small>Paso 2</small>Confirma tu Pago</div>
              </div>
              <div class="stepwizard-step">
                <div id="header_step3" href="#step-3" type="button" class="btn btn-default" style="cursor:auto"><small>Paso 3</small>Comprobante de Pago</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row " id="step-4">
        <div class="col-12">
            <h1 style="margin: 2.3733333333rem 0 1.424rem 0;" *ngIf="topMessagePagado=='OK'">Tu pago está listo </h1>
            <h1 style="margin: 2.3733333333rem 0 1.424rem 0;" *ngIf="topMessageAnulado=='OK'">Tu pago ha sido anulado </h1>
            <div class="col-md-8 offset-md-2">
              <div col-12 mb-0 mt-3 text-center *ngIf="imageDigital=='OK'"> <img src="../../../../assets/img/imageDigital.png" width="465px"> </div>
            </div>
            
            <div class="recuadro col-md-8 offset-md-2" *ngIf="topMessagePagado=='OK'">
                <div class="col-12 mb-0 mt-3 text-center">
                    <!-- <strong class="aqua" style="font-weight: bold; margin-top: 15px;"><br>Pago Exitoso</strong> -->
                    <strong class="aqua" style="font-weight: bold; margin-top: 15px;"><br>{{message}}</strong>
                </div>

                

                <div class="float-left col-md-7"><small><strong>RUT DEL PACIENTE:</strong></small></div>
                <div class="float-left col-md-5 text-right" id="rutPaciente">{{rutPaciente | rut}}</div>
                <div class="float-left col-md-7"><small><strong>VALOR FINAL PAGADO:</strong></small> </div>
                <div class="float-left col-md-5 text-right" id="valorFinal"><strong>{{valorFinal | thousands:0}}</strong> </div>
                <div class="col-md-12"> &nbsp;</div>
            </div>
            <br>
            <div class="col-12 col-md-8 offset-md-2" style="font-size: 18px; color: #009999; text-align: center;" *ngIf="topMessagePagado=='OK'">
              Se ha enviado a tu correo el comprobante de pago (Flow)
            </div>
            <!-- <br>
                    <div class="col-12 col-md-8 offset-md-2" style="font-size: 18px; color: #009999; text-align: center;">
                    Al fin de tu consulta médica enviaremos el bono a tu correo electrónico.
                    </div>
                <div class="col-12 col-md-8 offset-md-2" style="font-size: 18px; color: #009999; text-align: center;">
                    Para obtener tu descuento en caja los andes debes reembolsar por tu isapre y enviar el reembolso a  <a target="_blank" href="https://misucursal.cajalosandes.cl/webcenter/faces/oracle/webcenter/page/scopedMD/s8bba98ff_4cbb_40b8_beee_296c916a23ed/businessRolePages/Page163.jspx">https://misucursal.cajalosandes.cl/</a>
                </div>
            <br> -->
            <div class=" recuadro col-md-6 offset-md-3 text-center">
                <p style="font-size: 15px" *ngIf="footerPagado=='OK'">¿Necesitas ayuda?<br>
                    <span class="aqua" style="font-weight: bold; margin-top: 13px;font-size: 14px">Escríbenos a contacto.pacientes@redsalud.cl <br><span class="aqua" style="font-weight: bold; margin-top: 13px;font-size: 12px"></span></span>
                </p>
                <p style="font-size: 15px" *ngIf="footerAnulado=='OK'">¿Necesitas ayuda?<br>
                  <span class="aqua" style="font-weight: bold; margin-top: 13px;font-size: 18px">Llámanos al 600 718 6000 <br><span class="aqua" style="font-weight: bold; margin-top: 13px;font-size: 12px"></span></span>
              </p>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12">
            <div class="text-center"></div>
        </div>
    </div>
    <ngx-spinner></ngx-spinner>
