export class ResponseConsultaPrecio{

    precio:string;
    recargo:string;
    porcRecargo:string;
    codItemImed:string;
    tolerancia:string;
    especImed:string;
    enConvenio:string;
    rutConvenio:string;
    codigoLugarImed:number;
    codigoFinanciador:string;
    constructor(
        precio:string,
        recargo:string,
        porcRecargo:string,
        codItemImed:string,
        tolerancia:string,
        especImed:string,
        enConvenio:string,
        rutConvenio:string,
        codigoLugarImed:number,
        codigoFinanciador:string,
    ){
        this.precio = precio;
        this.recargo = recargo;
        this.porcRecargo = porcRecargo;
        this.codItemImed = codItemImed;
        this.tolerancia = tolerancia;
        this.especImed = especImed;
        this.enConvenio = enConvenio;        
        this.rutConvenio = rutConvenio;
        this.codigoLugarImed = codigoLugarImed;
        this.codigoFinanciador = codigoFinanciador;
    }
}