export class ResponsePaymentCreate{
    url:string;
    token:string;
    flowOrder:string;
    code:string;
    message:string;
    TipoMsj:string;
    Accion:string;
    constructor(
        url:string,
        token:string,
        flowOrder:string,
        code:string,
        message:string,
        TipoMsj:string,
        Accion:string
    ){
        this.url = url;
        this.token = token;
        this.flowOrder = flowOrder;
        this.code = code;
        this.message = message;
        this.TipoMsj = TipoMsj;
        this.Accion = Accion;
    }
}