export class RequestGenPrebonoLisPrestacion{

    CodPrestacion:string;
    CodItem:string;
    Cantidad:string;
    MtoTotal:string;
    constructor(
        CodPrestacion:string,
        CodItem:string,
        Cantidad:string,
        MtoTotal:string
    ){
        this.CodPrestacion = CodPrestacion;
        this.CodItem = CodItem;
        this.Cantidad = Cantidad;
        this.MtoTotal = MtoTotal;
    }
}