import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PAGES_ROUTES } from './pages.routes';
import { S1InfoPatientComponent } from './s1-info-patient/s1-info-patient/s1-info-patient.component';
import { S2ConfirmPaymentComponent } from './s2-confirm-payment/s2-confirm-payment/s2-confirm-payment.component';
import { S3DetailPaymentComponent } from './s3-detail-payment/s3-detail-payment/s3-detail-payment.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ThousandsPipe } from '../pipes/thousands.pipe';
import { RutPipe } from '../pipes/rut.pipe';

@NgModule({
  declarations: [
    S1InfoPatientComponent,
    S2ConfirmPaymentComponent,
    S3DetailPaymentComponent,
    ThousandsPipe,
    RutPipe
  ],
  imports: [
    CommonModule,
    PAGES_ROUTES,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
  ],
  providers: [
    ThousandsPipe,
    RutPipe
  ]
})
export class PagesModule { }
