<!doctype html>
<html lang="es">
<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Pago Web Ambulatorio | RedSalud</title>
</head>
<body>  
  <div class="wrapper">
    <header>
      <img src="../assets/img/logo.png" />
    </header>
    <div class="row col m-0 p-0">
      <div class="col-12 container">
        <h1>Pago Web Ambulatorio RedSalud</h1>
        <div class="stepwizard col-md-offset-3">
          <div class="stepwizard col-md-offset-3">
            <div class="stepwizard-row setup-panel">
              <div class="stepwizard-step">
                <div id="header_step1" href="#step-1" type="button" class="btn btn-primary" style="cursor:auto"><small>Paso 1</small>Información del Paciente</div>
              </div>
              <div class="stepwizard-step">
                <div id="header_step2" href="#step-2" type="button" class="btn btn-default" style="cursor:auto" disabled="disabled"><small>Paso 2</small>Confirma tu Pago</div>
              </div>
              <div class="stepwizard-step">
                <div id="header_step3" href="#step-3" type="button" class="btn btn-default" style="cursor:auto" disabled="disabled"><small>Paso 3</small>Comprobante de Pago</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>        
  <!-- </div> -->
    <!-- <div class="cargando ocultar" style="width: 100%; text-align:center;">
      <img src="img/loading.gif" style="display: block;margin: 0 auto;"/>
    </div> -->
  <!-- <div class="row " id="step-1"> -->
  <form class="mt-3">
    <div class="col-12">
      <div class="row col-md-12 m-0">
        <h2>Ingresa datos del <strong>Paciente</strong></h2>
        <p style="color: #222">IMPORTANTE: Se deben ingresar los datos de la persona a la que se le emitirá el bono de atención.</p>
        <div [formGroup]="dataForm" class="col-md-8 offset-md-2 p-0">
          <label for="nombre">Nombre del <strong>Paciente</strong> <span class="aqua">*</span></label>          
          <input type="text" id="nombre" name="nombre" formControlName="nombre" readonly="readonly" 
          [(ngModel)]="s1InfoPatient.nombre" 
          required 
          >          
        </div>
        <div [formGroup]="dataForm" class="col-md-8 offset-md-2 p-0">
          <label for="rut">Rut del <strong>Paciente</strong> <span class="aqua">*</span></label>          
          <input type="text" id="rut" name="rut" formControlName="rut" readonly="readonly" 
          [(ngModel)]="s1InfoPatient.rut"
          required
          >                    
        </div>
        <div [formGroup]="dataForm" class="col-md-8 offset-md-2 p-0">
          <label for="email">E-mail de notificación <span class="aqua">*</span></label>        
          <input type="text" id="correo" name="correo" formControlName="correo" readonly="readonly" 
          [(ngModel)]="s1InfoPatient.correo" required
          >            
        </div>
        <div [formGroup]="dataForm" class="col-md-8 offset-md-2 p-0">
          <label for="celular">Teléfono <span class="aqua">*</span></label>          
          <input type="text" id="telefono" name="teledono" formControlName="telefono" readonly="readonly" 
          [(ngModel)]="s1InfoPatient.telefono" required
          >            
        </div>

        <div class="col-md-8 offset-md-2 p-0">
          <label for="selectIsapre">Plan de Salud <span class="aqua">*</span></label>
        </div>

        <div [formGroup]="dataForm" class="col-md-8 offset-md-2 p-0">          
          <select formControlName="Codigo" [(ngModel)]="s1InfoPatient.Codigo" placeholder="Aseguradoras">
            <option *ngFor="let item of listAseguradoras" value="{{item.Codigo}}">{{item.Descripcion}}</option>
          </select>          
        </div>

        <!-- <div class="col-md-8 offset-md-2 p-0">
          <div [formGroup]="dataForm" class="">
            <div class="recaptcha d-flex align-items-center justify-content-center">                    
              <re-captcha (resolved)="resolved($event)" siteKey="6LeQMMEZAAAAALzGBcJ-BHsAsXzVMsXN7-V7W2-p">                            
              </re-captcha>                                                  
            </div>   
            <div class="row justify-content-center">        
              <input style="display: none;" name="recaptcha" id="recaptcha" formControlName="recaptcha"
                        [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }" [(ngModel)]="recaptcha"/>
              <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
                <div *ngIf="f.recaptcha.errors.required" style="margin-left: 70px;margin-top: -10px;">Verificación CAPTCHA es obligatorio</div>
              </div>    
            </div>  
          </div>          
        </div> -->
        <!-- Message -->
        
        <!-- <div class="row">
          <div class="container">
              <div [formGroup]="dataForm" class="text-center">
                  <input type="hidden" id="messages" name="messages" formControlName="messages" [(ngModel)]="messages"
                  [ngClass]="{ 'is-invalid': submitted && (isMessagesMismatch || f.confirm.errors) }">
                  <p class="text-info" bold>{{this.message}}</p>
              </div>
          </div>                            
        </div>  -->

        <!-- Re Captcha-->
        <!-- <div class="row">
          <div class="container">
            <div [formGroup]="dataForm" class="">
                <div class="recaptcha d-flex align-items-center justify-content-center">
                    
                    <re-captcha (resolved)="resolved($event)" siteKey="6LeQMMEZAAAAALzGBcJ-BHsAsXzVMsXN7-V7W2-p">                            
                    </re-captcha>                                                  
                </div>   
                <div class="row justify-content-center">        
                    <input style="display: none;" name="recaptcha" id="recaptcha" formControlName="recaptcha"
                            [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }" [(ngModel)]="recaptcha"/>
                    <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
                        <div *ngIf="f.recaptcha.errors.required" style="margin-left: 70px;margin-top: -10px;">Verificación CAPTCHA es obligatorio</div>
                    </div>    
                </div>                 
            </div>   
          </div>    
        </div>
       -->

        <!-- <div id="row_dim">
          <div class="col-md-8 offset-md-2 p-0 input-field">
              <input id="serial" name="serial" type="text" class="validate"  min_length='9' max_length='9' value="">
              <label for="serial">Número de documento / Serie *</label>
          </div>
          <div class="row" style="padding-top: 30px;">
              <div class="col-md-5 offset-md-1 text-center" ><img width="100%" src="../assets/img/cedula_nueva.png"> <br> Cédula Nueva</div>
              <div class="col-md-5 text-center"><img width="100%" src="../assets/img/cedula_antigua.png"> <br> Cédula Antigua</div>
          </div>
        </div> -->

        <!-- <div class="col-md-8 offset-md-2 p-0 br10 aqua">
          <p>* Campos Obligatorios</p>
        </div> -->

        <p id="error_paso1_email" class="error ocultar">Ingrese un formato de correo válido, verifique e intente nuevamente</p>
        <p id="error_paso1_telefono" class="error ocultar">Ingrese un formato de teléfono válido, ej: 56987654321</p>
        <p id="error_paso1_isapre" class="error ocultar">Debe seleccionar un plan de salud</p>
        <p id="error_paso1_serie" class="error ocultar">Debe ingresar un número de documento/serie válido</p>

        <div class="col-12 text-center mt-3 mb-4">            
            <button class="botpaso1  btn waves-effect waves-light baqua" id ="btn-next" type="button" name="action" (click)="s2Page($event)" [disabled]="dataForm.invalid">Siguiente
            <!-- <button class="botpaso1  btn waves-effect waves-light baqua" id ="btn-next" type="button" name="action" (click)="s2Page()" [disabled]="this.recaptcha==null">Pagar -->
            <i class="material-icons right">  <img src="../assets/img/arrow_right.png" ></i>
            </button>
        </div>
      </div>
    </div>
  </form>
  </div>
  
</body>
</html>
<ngx-spinner></ngx-spinner>
