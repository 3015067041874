<!doctype html>
<html lang="es">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Pago Web Ambulatorio | RedSalud</title>
</head>

<body>
  <div class="wrapper">
    <header>
      <img src="../assets/img/logo.png" />
    </header>
    <div class="row col m-0 p-0">
      <div class="col-12 container">
        <h1>Pago Web Ambulatorio RedSalud</h1>
        <div class="stepwizard col-md-offset-3">
          <div class="stepwizard col-md-offset-3">
            <div class="stepwizard-row setup-panel">
              <div class="stepwizard-step">
                <div id="header_step1" href="#step-1" type="button" class="btn btn-primary" style="cursor:auto"
                  disabled="disabled"><small>Paso 1</small>Información del Paciente</div>
              </div>
              <div class="stepwizard-step">
                <div id="header_step2" href="#step-2" type="button" class="btn btn-default" style="cursor:auto">
                  <small>Paso 2</small>Confirma tu Pago</div>
              </div>
              <div class="stepwizard-step">
                <div id="header_step3" href="#step-3" type="button" class="btn btn-default" style="cursor:auto"
                  disabled="disabled"><small>Paso 3</small>Comprobante de Pago</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row " id="step-3" style="display:none;"> -->
    <form>
      <div class="col-12">
        <div class="row col-md-12 m-0">
          <h2 class="text-center">Confirma y realiza el pago de tu atención para la generación de tu bono electrónico
          </h2>
          <div class="recuadro col-md-8 offset-md-2 text-left">
            <br>
            <div class="float-left col-md-8"><small><strong>RUT DEL PACIENTE: </strong></small></div>
            <div class="float-left col-md-4 text-right" id="rutPaciente"> <strong>{{rutPaciente | rut}}</strong></div>
            <div class="float-left col-md-8"><small>VALOR TOTAL DE LA PRESTACION:</small></div>
            <div class="float-left col-md-4 text-right" id="valorPrestacion">{{valorPrestacion | thousands:0}}</div>
            <div class="float-left col-md-8"><small>BONIFICACION FONASA/ISAPRE:</small></div>
            <div class="float-left col-md-4 text-right" id="valorCopago">{{valorCopago | thousands:0}}</div>
            <div class="float-left col-md-8"><small>BONIFICACION SEGUROS COMPLEMENTARIOS:</small></div>
            <div class="float-left col-md-4 text-right" id="valorSeguro">{{valorSeguro | thousands:0}}</div>
            <div class="float-left col-md-8"><small><strong>TOTAL A PAGAR / COPAGO: </strong></small> </div>
            <div class="float-left col-md-4 text-right" id="valorFinal"><strong>{{valorFinal | thousands:0}}</strong>
            </div>
            <div class="float-left col-md-12">&nbsp;</div>
            <div class="col-md-12"> &nbsp;</div>
          </div>
        </div>
        <div class="payment">
          <h2 class="text-center">Selecciona método de pago</h2>
          <div class="row col-12 p-0 m-0 mb-5 text-center">
            <div class="col-6 col-md">
              <div class="card" id="card-1" data-id="1">
                <img class="card-img-top" src="https://sandbox.flow.cl/img/logo_webpay.png" alt="WebPay">
                <div [formGroup]="dataForm" class="custom-control custom-radio">
                  <input id="webpay" type="radio" class="custom-control-input" value="webpay" name="methodPayment"
                    formControlName="methodPayment" [(ngModel)]="s2ConfirmPayment.methodPayment">
                  <label class="custom-control-label" for="webpay">Webpay</label>
                </div>
              </div>
            </div>
            <div class="col-6 col-md">
              <div class="card" id="card-5" data-id="5">
                <img class="card-img-top" src="https://sandbox.flow.cl/img/logo_onepay.png" alt="OnePay">
                <div [formGroup]="dataForm" class="custom-control custom-radio">
                  <input id="onepay" type="radio" class="custom-control-input" value="onepay" name="methodPayment"
                    formControlName="methodPayment" [(ngModel)]="s2ConfirmPayment.methodPayment">
                  <label class="custom-control-label" for="onepay">Onepay</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="terminos">
          <div class="row col-12 p-0 m-0 mb-5 text-center">            
            <div class="col-md-12">
              <div class="card" id="card-5" data-id="5"> 
                <div class="form-group">
                  <div [formGroup]="dataForm" class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="terminoCondiciones"
                        formControlName="terminoCondiciones" [(ngModel)]="s2ConfirmPayment.terminoCondiciones">
                      <label class="custom-control-label" for="terminoCondiciones">Declaro que acepto los <a href="https://www.redsalud.cl/assets/media/2024-02-15-terminos-y-condiciones-pago-web-ambulatorio.pdf" target="_blank">Términos y Condiciones</a></label>
                  </div>
                </div>
              </div>
            </div>                    
          </div>
        </div>

        <div class="pagar">
          <div class="row col-12 p-0 m-0 text-center">
            <div class="col-6">
              <button type="button" (click)="s1Page()" class="btn waves-effect waves-light baqua" style="width:100%;">
                Volver
              </button>
            </div>
            <div class="col-6">
              <button id="btn-pago" [disabled]="clicked" type="button" (click)="s3Page($event); clicked = true;"
                class="btn waves-effect waves-light baqua" style="width:100%;" [disabled]="dataForm.invalid">
                Pagar
              </button>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </form>
  </div>
  <ngx-spinner></ngx-spinner>