import { RequestPagoPrebonoLisPreBonoPrestacionesInpLisFormasPagoBono } from './requestPagoPrebonoLisPreBonoPrestacionesInpLisFormasPagoBono';
export class RequestPrebonoLisPreBonoPrestacionesInp {

    NumPreBono:number;
    LisFormasPagoBono:RequestPagoPrebonoLisPreBonoPrestacionesInpLisFormasPagoBono[]=[];
    constructor(
        NumPreBono:number,
        LisFormasPagoBono:RequestPagoPrebonoLisPreBonoPrestacionesInpLisFormasPagoBono[]=[]
    ){
        this.NumPreBono = NumPreBono;
        this.LisFormasPagoBono = LisFormasPagoBono;
    }
}