import { RequestPagoPrebono } from '../model/s2-confirm-payment/requestPagoPrebono';
import { Injectable } from '@angular/core';
import { map ,catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { RequestGeneraPrebono } from '../model/s2-confirm-payment/requestGeneraPrebono';
import { RequestPaymentCreate } from '../model/s2-confirm-payment/requestPaymentCreate';
import { RequestConsultaPrecio } from '../model/s2-confirm-payment/requestConsultaPrecio';

@Injectable({
  providedIn: 'root'
})
export class S2ConfirmPaymentService {

  constructor(private http: HttpClient) { }

  async consultaPrecio(requestConsultaPrecio:RequestConsultaPrecio, codCentro: string){    

    let myHeaders = new HttpHeaders();

    myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
    let requestOptions = {
      headers: myHeaders,
      reportProgress: true,
    };

    const body = { 'CodPrestacion': requestConsultaPrecio.codPrestacion,
                   'CodConvenio': requestConsultaPrecio.codConvenio,
                   'Fecha': requestConsultaPrecio.fecha,
                   'Hora': requestConsultaPrecio.hora,
                   'IdCita': requestConsultaPrecio.idCita
    };
  
    return this.http.post(environment.url_services+`/api/his/consultaPrecio?codCentro=${codCentro}`,body,requestOptions)
                    .pipe(
                          map( (resp: any) => {
                            //console.log('response', resp);
                          return resp;
                  
                          }),
                          catchError(err => {
                  
                            console.log("Error is handled", err);
                  
                            return err;
                          }))
                    .toPromise();
  }; 

  // async dataFinanciador(financiadorHIS:string, codCentro:string){    

  //   let myHeaders = new HttpHeaders();

  //   myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
  //   let requestOptions = {
  //     headers: myHeaders,
  //     reportProgress: true,
  //   };

  //   const body = { 
  //     'financiadorHIS': financiadorHIS,
  //     'codCentro': codCentro
  //   };
  
  //   return this.http.post(environment.url_services+"/api/dataFinanciador",body,requestOptions)
  //                   .pipe(
  //                         map( (resp: any) => {
  //                           //console.log('response', resp);
  //                         return resp;
                  
  //                         }),
  //                         catchError(err => {
                  
  //                           console.log("Error is handled", err);
                  
  //                           return err;
  //                         }))
  //                   .toPromise();
  // };

  async genPrebono(requestGenPrebono:RequestGeneraPrebono, codLugar:string, rutConvenio:string){    

    let myHeaders = new HttpHeaders();

    myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
    let requestOptions = {
      headers: myHeaders,
      reportProgress: true,
    };
  
    return this.http.post(environment.url_services+`/api/imed/genPrebono?codLugar=${codLugar}&rutConvenio=${rutConvenio}`,requestGenPrebono,requestOptions)
                    .pipe(
                          map( (resp: any) => {
                            //console.log('response', resp);
                          return resp;
                  
                          }),
                          catchError(err => {
                  
                            console.log("Error is handled", err);
                  
                            return err;
                          }))
                    .toPromise();
  };
  
  async paymentCreate(requestPaymentCreate:RequestPaymentCreate){
    
  let myHeaders = new HttpHeaders(
    {'Content-Type': 'application/x-www-form-urlencoded'}
  );

  myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
  let requestOptions = {
    headers: myHeaders,
    reportProgress: true,
  };

  const params = new HttpParams({
                  fromObject: { 
                              apiKey: requestPaymentCreate.apiKey,
                              commerceOrder: requestPaymentCreate.commerceOrder,
                              subject: requestPaymentCreate.subject,
                              currency: requestPaymentCreate.currency,
                              amount: requestPaymentCreate.amount,
                              email: requestPaymentCreate.email,
                              paymentMethod: requestPaymentCreate.paymentMethod,
                              urlConfirmation: requestPaymentCreate.urlConfirmation,
                              urlReturn: requestPaymentCreate.urlReturn,
                              optional: requestPaymentCreate.optional,
                              timeout: requestPaymentCreate.timeout,
                              merchantId: requestPaymentCreate.merchantId,
                              payment_currency: requestPaymentCreate.payment_currency        
                            }
                  });
    
  return this.http.post(environment.url_services+"/api/flow/paymentCreate",params,requestOptions)
                  .pipe(
                        map( (resp: any) => {
                          //console.log('response', resp);
                          return resp;              
                        }),
                        catchError(err => {              
                          console.log("Error is handled", err);              
                          return err;
                        }))
                  .toPromise();
  };

  async pagoCompletadoCopago0(idCita:string){    

    let myHeaders = new HttpHeaders();

    myHeaders = myHeaders.set("Authorization", environment.token_api_kocha)
    let requestOptions = {
      headers: myHeaders,
      reportProgress: true,
    };
  
    return this.http.post(environment.url_services+`/api/pagoCompletadoCopago0?idCita=${idCita}`,idCita,requestOptions)
                    .pipe(
                          map( (resp: any) => {
                            //console.log('response', resp);
                          return resp;
                  
                          }),
                          catchError(err => {
                  
                            console.log("Error is handled", err);
                  
                            return err;
                          }))
                    .toPromise();
  };

}
