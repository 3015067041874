import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { S1InfoPatient } from '../../../model/s1-info-patient/s1-info-patient'
import { Desencriptado } from '../../../model/s1-info-patient/desencriptado';
import { ResponseConsultaCitasByGuid} from '../../../model/s1-info-patient/responseConsultaCitasByGuid';
import { S1InfoPatientService } from '../../../service/s1-info-patient.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ResponseConsultaCitasByGuidAseguradores } from 'src/app/model/s1-info-patient/responseConsultaCitasByGuidAseguradores';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-s1-info-patient',
  templateUrl: './s1-info-patient.component.html'
})
export class S1InfoPatientComponent implements OnInit {

  dataForm = new FormGroup({
    nombre: new FormControl(),
    rut: new FormControl(),
    correo: new FormControl(),
    telefono: new FormControl(),
    Codigo: new FormControl()
  });

  s1InfoPatient: S1InfoPatient = new S1InfoPatient("","","","","","");
  desencriptadoModel: Desencriptado = new Desencriptado("", "", "", "", "", "", "", "", "", "", "", "","","","","");
  responseConsultaCitasByGuidModel: ResponseConsultaCitasByGuid = new ResponseConsultaCitasByGuid("","","","",[]);
  listAseguradoras: ResponseConsultaCitasByGuidAseguradores[] = []
  
  cod_convenio:string = "";
  nombrePaciente:string = "";
  rutPaciente:string="";
  emailNotificacion:string="";  
  recaptcha: any[] = null;
  submitted = false;
  message:string = "";
  messages:string = "";

  sked:any

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private service: S1InfoPatientService,
              private spinner: NgxSpinnerService) {}

  async ngOnInit() {
    this.spinner.show()
    this.sked = window.location.href.split('id=')
    this.sked = this.sked[this.sked.length-1]          
    console.log('requestDesencriptado: ',this.sked)
    this.desencriptadoModel = await this.desencriptar(this.sked);
    console.log('responseDesencriptado: ', this.desencriptadoModel)

    if(this.desencriptadoModel.resultado == "-1"){
      this.spinner.hide()
      Swal.fire({
        text: this.desencriptadoModel.glosa,
        confirmButtonText: 'Cerrar',
      }).then((result) => {
        if(result.isConfirmed){
          this.redirectResaludXDesencriptado();
        }
      })
    }else{
      console.log('requestConsultaCitasByGuid: ', this.desencriptadoModel.cita)
      this.responseConsultaCitasByGuidModel = await this.citasPacientesByGuid(this.desencriptadoModel.cita)
      console.log('responseConsultaCitasByGuid: ', this.responseConsultaCitasByGuidModel)
      if(this.responseConsultaCitasByGuidModel.Resultado == "10"){
        this.spinner.hide()
        Swal.fire({
          text: this.responseConsultaCitasByGuidModel.GlosaResultado,
          confirmButtonText: 'Cerrar',
        }).then((result) => {
          if(result.isConfirmed){
            this.redirectResalud();
          }
        })
      }else if(this.responseConsultaCitasByGuidModel.Resultado == "-1"){
        this.spinner.hide()
        Swal.fire({
          text: this.responseConsultaCitasByGuidModel.GlosaResultado,
          confirmButtonText: 'Cerrar',
        }).then((result) => {
          if(result.isConfirmed){
            this.redirectResalud();
          }
        })
      }else if(this.responseConsultaCitasByGuidModel.Resultado == "1" && this.responseConsultaCitasByGuidModel.Citas[0].EstadoCita != "P"){
        this.spinner.hide()
        Swal.fire({
          text: "Lo sentimos, esta cita ha sido anulada o no se encuentra disponible.",
          confirmButtonText: 'Cerrar',
        }).then((result) => {
          if(result.isConfirmed){
            this.redirectResalud();
          }
        })
      }

      this.listAseguradoras = this.responseConsultaCitasByGuidModel.Citas[0].Aseguradores
      this.s1InfoPatient.nombre = this.responseConsultaCitasByGuidModel.Citas[0].NombreBeneficiario
      this.s1InfoPatient.rut = this.desencriptadoModel.rut
      this.s1InfoPatient.correo = this.desencriptadoModel.correo
      this.s1InfoPatient.telefono = this.desencriptadoModel.telefono
      this.spinner.hide()
      this.dataForm = this.formBuilder.group({
        nombre: ['', [Validators.required]],
        rut: ['', [Validators.required]],
        correo: ['', [Validators.required]],
        telefono: ['', [Validators.required]],
        Codigo: ['', [Validators.required]]
        //recaptcha: ['', [Validators.required]]
        
      }
      // , {
      //   validator: (form: FormGroup) => { 
      //     return null; //form.get('message').value !== "" ? { messageMismatch: true } : null 
      //   }
      //}
      );
    }

  }

  public checkError = (controlName: string, errorName: string) =>{
    return this.dataForm.controls[controlName].hasError(errorName);
  }

  async desencriptar(sked){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.desencriptar(sked)
      .then((data) => {
        resolve(data)              
      });
    })
  }  

  async citasPacientesByGuid(idSked){
    return new Promise<any>((resolve, reject) => {
      let res = this.service.citasPacientesByGuid(idSked)
      .then((data) => {             
        resolve(data)             
      });
    })
  } 

  get f() { return this.dataForm.controls; }
  get isMessagesMismatch() { return this.dataForm.getError('messagesMismatch')}

  resolved(captchaResponse: any[]){
    this.recaptcha = captchaResponse;
    console.log('recaptcha', this.recaptcha);
  }

  postData() {   
    this.submitted = true;
    if(this.dataForm.invalid) {  
    } else {
      console.log('DataForm: ',JSON.stringify(this.dataForm.value));
    if (
      this.recaptcha == undefined     
    ) {         
      return;     
      }   
    }
  }

  redirectResalud(){
    window.location.href = this.responseConsultaCitasByGuidModel.Accion;
  }

  redirectResaludXDesencriptado(){
    window.location.href = this.desencriptadoModel.accion;
  }

  s2Page(event: Event){
    console.log('dataForm: ',this.dataForm) 
    let route = '/S2ConfirmPayment';
    this.router.navigate([route], { state: { responseConsultaCitasByGuidModel: this.responseConsultaCitasByGuidModel,
                                             convenio: this.s1InfoPatient.Codigo,                                            
                                             desencriptadoModel: this.desencriptadoModel
                        }});
  }
}
