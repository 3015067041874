export class RequestPagoPrebonoFormasPago{

    FormaPago:number;
    MtoPago:number;
    constructor(
        FormaPago:number,
        MtoPago:number
    ){
        this.FormaPago = FormaPago;
        this.MtoPago = MtoPago;
    }
}