export class S1InfoPatient{

    nombre:string;
    rut:string;    
    correo:string;
    telefono:string;
    Codigo:string;
    idCita:string
    constructor(        
        nombre:string,
        rut:string,
        correo:string,
        telefono:string,
        Codigo:string,
        idCita:string
    ){
        this.nombre = nombre;
        this.rut = rut;
        this.correo = correo;    
        this.telefono = telefono;
        this.Codigo = Codigo; 
        this.idCita = idCita;   
    }

}