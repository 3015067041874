import { ResponseConsultaCitasByGuidCitas } from './responseConsultaCitasByGuidCitas';


export class ResponseConsultaCitasByGuid{
    Resultado:string;
    GlosaResultado:string;
    TipoMsj:string;
    Accion:string;
    Citas:ResponseConsultaCitasByGuidCitas[] = [];
    constructor(        
        Resultado:string,
        GlosaResultado:string,  
        TipoMsj:string,
        Accion:string,
        Citas:ResponseConsultaCitasByGuidCitas[] = []
    ){
        this.Resultado = Resultado;
        this.GlosaResultado = GlosaResultado;
        this.TipoMsj = TipoMsj;
        this.Accion = Accion;
        this.Citas = Citas;    
    }

}