import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { S1InfoPatientComponent } from './s1-info-patient/s1-info-patient/s1-info-patient.component';
import { S2ConfirmPaymentComponent } from './s2-confirm-payment/s2-confirm-payment/s2-confirm-payment.component';
import { S3DetailPaymentComponent } from './s3-detail-payment/s3-detail-payment/s3-detail-payment.component';

const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: [
            {
              path: '',
              component: S1InfoPatientComponent,              
              data: { titulo: 'S1InfoPatient' }               
            }, 
            {
              path: 'S2ConfirmPayment',
              component: S2ConfirmPaymentComponent,
              data: { titulo: 'S2ConfirmPayment' }               
            }, 
            {
                path: 'S3DetailPayment',
                component: S3DetailPaymentComponent,
                data: { titulo: 'S3DetailPayment' }               
            }             
        ]
    },
    { 
        path: 'pages', 
        data: { titulo: 'pages' },
        component: PagesComponent,
        pathMatch: 'full' 
    }
];

console.log("router interno")
export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );